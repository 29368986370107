import React from 'react';
import PropTypes from 'prop-types';
import './error-info.scss';

const ErrorInfo = ({ errors }) => {
  if (!Object.values(errors).length) return null;

  return Object.values(errors).map((error, index) => (
    <div key={error+index} className="error-info">
      {error.map((text, index) => <p key={text+index}>{text}</p>)}
    </div>
  ));
};

ErrorInfo.propTypes = {
  errors: PropTypes.object.isRequired
};

export default ErrorInfo;
