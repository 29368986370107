const BASE_URI = process.env.REACT_APP_API_URL;

export default {
  registerUser: () => `${BASE_URI}/portrait`,
  login: () => `${BASE_URI}/account/login`,
  googleLogin: () => `${BASE_URI}/account/login/google?project=relations`,
  facebookLogin: (inviteKey) => `${BASE_URI}/account/login/facebook${inviteKey ? `?inviteKey=${inviteKey}` : ''}`,
  forgotPassword: () => `${BASE_URI}/account/password/email`,
  resetPassword: () => `${BASE_URI}/account/password/reset`,
  changePassword: () => `${BASE_URI}/account/password/change`,
  resendVerificationEmail: () => `${BASE_URI}/email/resend`,
  detachSocialAccount: (provider) => `${BASE_URI}/account/detach/${provider}`,
  generateNewShareKey: () => `${BASE_URI}/account/portrait/revokekey`,
  inviteFriend: () => `${BASE_URI}/account/invite`,
  getPortraitInfo: () => `${BASE_URI}/portrait/info`,
  getUserPortrait: (uuid) => `${BASE_URI}/portrait/${uuid}`,
  getUserConnections: () => `${BASE_URI}/portraitRelation`,
  addAvatar: () => `${BASE_URI}/account/portrait/storeavatar`,
  uploadImages: () => `${BASE_URI}/portrait/relations/storemedia`,
  storePortrait: () => `${BASE_URI}/relations_project/store_portrait`,
  getRelationshipData: (firstUuid, secondUuid) => `${BASE_URI}/compare?portrait1_id=${firstUuid}&portrait2_id=${secondUuid}`,
  getVideoaskLink: (source) => `${BASE_URI}/videoask/link/${source}`,
  getVacancies: () => `${BASE_URI}/hrmny-vacancies?company=ai`,
  joinWaitingList: () => `${BASE_URI}/waiting-list`,
  getPayment: (transition) => `${BASE_URI}/payment/checkout?transition=${transition}`,
  subscribe: () => `${BASE_URI}/subscribe`,
  unsubscribe: () => `${BASE_URI}/unsubscribe`,
};
